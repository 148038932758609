














































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { cashManagementServices } from "@/services/cashmanagement.service";
import moment from "moment";
import { settingsServices } from "@/services/settings.service";
import { DataMasterCurrency } from "@/models/interface/settings.interface";
import { masterServices } from "@/services/master.service";
import { DataListBankTransfer } from "@/models/interface/cashManagement.interface";
enum STATUSDATA {
  DRAFT = "Draft",
  CANCELLED = "Cancelled",
  NEED_APPROVAL = "Need Approval",
  APPROVED = "Approved",
  SETTLED = "Settled",
}
@Component
export default class BankTransfer extends Vue {
  currentPage = 1 as number;
  limit = 10;
  page = 0;
  dataList = [] as DataListBankTransfer[];
  totalData = 0 as number;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "bankTransfer" });
  downloadParam = {
    companyName:
      this.$store.state.authStore.authData.companyName ||
      "PT. SATRIA PIRANTI PERKASA",
    branch: "ALL",
    transferDateFrom: "ALL",
    transferDateTo: "ALL",
    transferNo: "ALL",
    status: "ALL",
    bankSource: "ALL",
    bankDestination: "ALL",
    currency: "ALL",
  };
  searchData = "" as any;
  columnsTable = [
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branch",
      scopedSlots: { customRender: "isNull" },
      width: 150,
    },
    {
      title: this.$t("lbl_transfer_no"),
      dataIndex: "documentNo",
      key: "transferNo",
      scopedSlots: { customRender: "isNull" },
      width: 300,
    },
    {
      title: this.$t("lbl_transfer_date"),
      dataIndex: "transferDate",
      key: "transferDate",
      scopedSlots: { customRender: "isDate" },
      width: 200,
    },
    {
      title: this.$t("lbl_currency"),
      dataIndex: "currency",
      key: "currency",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_bank_source"),
      dataIndex: "bankSource",
      key: "bankSource",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_bank_destination"),
      dataIndex: "bankDestination",
      key: "bankDestination",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: this.$t("lbl_amount"),
      dataIndex: "transferAmount",
      key: "amount",
      scopedSlots: { customRender: "isCurrency" },
      width: 200,
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      scopedSlots: { customRender: "isNull" },
      width: 200,
    },
    {
      title: "Action",
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ] as ColumnTableCustom;
  formRules = {
    transferDateFrom: {
      decorator: ["transferDateFrom"],
    },
    transferDateTo: {
      decorator: ["transferDateTo"],
    },
    branch: {
      decorator: ["branch"],
    },
    transferNo: {
      decorator: ["transferNo"],
    },
    bankSource: {
      decorator: ["bankSource"],
    },
    bankDestination: {
      decorator: ["bankDestination"],
    },
    currency: {
      decorator: ["currency"],
    },
    status: {
      decorator: ["status"],
    },
  };
  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
  dataBranch = [] as DataWarehouseBranch[];
  dataTransferNo = [] as any[];
  dataBankSource = [] as any[];
  dataBankDestination = [] as any[];
  dataStatus = [] as any[];
  dataCurrency = [] as DataMasterCurrency[];
  loading = {
    branch: false as boolean,
    table: false as boolean,
    find: false as boolean,
    download: false as boolean,
    transferNo: false as boolean,
    currency: false as boolean,
    bankSource: false as boolean,
    bankDestination: false as boolean,
    status: false as boolean,
  };
  searchDropdown(value, type) {
    if (value) {
      switch (type) {
        case "branch":
          this.getBranch(value);
          break;
        case "transferNo":
          this.getTransferNo(value);
          break;
        case "currency":
          this.getCurrency(value);
          break;
        case "bankSource":
          this.getListBankSourceDestination(type, value);
          break;
        case "bankDestination":
          this.getListBankSourceDestination(type, value);
          break;
        default:
          break;
      }
    }
  }

  getCurrency(value) {
    let params = {
      limit: 10,
      page: 0,
    } as RequestQueryParamsModel;
    this.loading.currency = true;
    if (value)
      params.search = `currencyCode~*${value}*_OR_description~*${value}`;
    return settingsServices
      .listOfMasterCurrency(params, "")
      .then(res => {
        this.dataCurrency = res.data;
      })
      .finally(() => (this.loading.currency = false));
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  responseViewTable(response) {
    this.$router.push(
      "/cash-management/transactions/bank-transfer/edit/" + response.data.id
    );
  }
  findData(page): void {
    this.form.validateFields((err, value) => {
      if (!err) {
        const params: RequestQueryParamsModel = {
          page: page ? this.page : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        for (const key in this.downloadParam) {
          if (key != "companyName") {
            this.downloadParam[key] = "ALL";
          }
        }
        if (this.dynamicSearch(value)) {
          this.searchData = this.dynamicSearch(value);
          params.search = this.dynamicSearch(value);
        }
        this.getData(params);
      }
    });
  }
  getData(params) {
    this.loading.table = true;
    cashManagementServices
      .listBankTransfer(params)
      .then(res => {
        res.data.forEach(item => {
          switch (item.status) {
            case "NEED_APPROVAL":
              item.status = STATUSDATA.NEED_APPROVAL;
              break;
            case "DRAFT":
              item.status = STATUSDATA.DRAFT;
              break;
            case "CANCELLED":
              item.status = STATUSDATA.CANCELLED;
              break;
            case "APPROVED":
              item.status = STATUSDATA.APPROVED;
              break;
            case "SATTLED":
              item.status = STATUSDATA.SETTLED;
              break;
            default:
              break;
          }
        });
        this.totalData = res.totalElements;
        this.dataList = res.data;
      })
      .finally(() => (this.loading.table = false));
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  assignSearch(key, value, operator): string {
    if (key === "branch" && value) {
      this.downloadParam.branch = this.dataBranch.find(item => item.id == value)
        ?.name as string;
      return operator + `branchWarehouse.secureId~${value}`;
    } else if (key === "transferNo" && value) {
      this.downloadParam.transferNo = value;
      return operator + `documentNo~${value}`;
    } else if (key === "transferDateFrom" && value) {
      this.downloadParam.transferDateFrom =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transferDate>=${moment(value)
          .set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .format()}`
      );
    } else if (key === "transferDateTo" && value) {
      this.downloadParam.transferDateTo =
        moment(value).format(DEFAULT_DATE_FORMAT);
      return (
        operator +
        `transferDate<=${moment(value)
          .set("hour", 23)
          .set("minute", 59)
          .set("second", 59)
          .format()}`
      );
    } else if (key === "currency" && value) {
      this.downloadParam.currency = this.dataCurrency.find(
        item => item.id == value
      )?.currencyCode as string;
      return operator + `currency.secureId~${value}`;
    } else if (key === "status" && value) {
      let statusChange = "" as string;
      switch (value) {
        case "NEED_APPROVAL":
          statusChange = STATUSDATA.NEED_APPROVAL;
          break;
        case "DRAFT":
          statusChange = STATUSDATA.DRAFT;
          break;
        case "CANCELLED":
          statusChange = STATUSDATA.CANCELLED;
          break;
        case "APPROVED":
          statusChange = STATUSDATA.APPROVED;
          break;
        default:
          break;
      }
      this.downloadParam.status = statusChange;
      return operator + `status~${value}`;
    } else if (key === "bankSource" && value) {
      this.downloadParam.bankSource =
        this.dataBankSource.find(item => item.id === value).bankName +
        " - " +
        this.dataBankSource.find(item => item.id === value).bankAccNumber;
      return operator + `bankSource.secureId~${value}`;
    } else if (key === "bankDestination" && value) {
      this.downloadParam.bankDestination =
        this.dataBankDestination.find(item => item.id === value).bankName +
        " - " +
        this.dataBankDestination.find(item => item.id === value).bankAccNumber;
      return operator + `bankDestination.secureId~${value}`;
    } else return "";
  }
  clearItems(): void {
    this.form.resetFields();
  }
  CreateNew(): void {
    this.$router.push("/cash-management/transactions/bank-transfer/create");
  }
  download() {
    let params = {
      params: `${this.downloadParam.companyName},${this.downloadParam.branch},${this.downloadParam.transferNo},${this.downloadParam.transferDateFrom},${this.downloadParam.transferDateTo},${this.downloadParam.bankSource},${this.downloadParam.bankDestination},${this.downloadParam.currency},${this.downloadParam.status}`,
    } as RequestQueryParamsModel;
    if (this.searchData) {
      params.search = this.searchData;
    }
    cashManagementServices.downloadBankTransfer(params).then(res => {
      if (res) {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "bank-transfer.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    });
  }
  mounted() {
    this.getBranch("");
    this.getCurrency("");
    this.getTransferNo("");
    this.getStatus("");
    this.getListBankSourceDestination("", "");
  }
  getTransferNo(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `documentNo~*${value}*`;
    this.loading.transferNo = true;
    cashManagementServices
      .listBankTransfer(params)
      .then(res => {
        this.dataTransferNo = res.data.filter(item => item.documentNo);
      })
      .finally(() => (this.loading.transferNo = false));
  }
  getStatus(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value) params.search = `status~*${value}*`;
    this.loading.status = true;
    cashManagementServices
      .listBankTransferStatus(params)
      .then(res => {
        this.dataStatus = res;
      })
      .finally(() => (this.loading.status = false));
  }
  getListBankSourceDestination(type, value) {
    let params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      sort: `createdDate:desc`,
    };
    if (!type) {
      this.loading.bankSource = true;
      this.loading.bankDestination = true;
    } else {
      if (type === "bankSource") {
        this.loading.bankSource = true;
      } else {
        this.loading.bankDestination = true;
      }
    }
    if (value) {
      params.search = `bankAccName~*${value}*_OR_bankAccNumber~*${value}*_OR_bankName~*${value}*`;
    }
    masterServices
      .listCompanyBank(params)
      .then(res => {
        if (!type) {
          this.dataBankSource = res.data;
          this.dataBankDestination = res.data;
        } else {
          if (type === "bankSource") {
            this.dataBankSource = res.data;
          } else {
            this.dataBankDestination = res.data;
          }
        }
      })
      .finally(() => {
        if (!type) {
          this.loading.bankSource = false;
          this.loading.bankDestination = false;
        } else {
          if (type === "bankSource") {
            this.loading.bankSource = false;
          } else {
            this.loading.bankDestination = false;
          }
        }
      });
  }
  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  handleClickColumn(record, objColumnNameValue) {
    this.$router.push(
      "/generaljournal/journal/detail/" + record.journalNumberId
    );
  }
  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 0;
    this.currentPage = 1;
    this.findData(true);
  }
  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page - 1;
    this.currentPage = response.page;
    this.findData(true);
  }
}
